<template>
  <div>
    <div class="uppercase font-medium text-xs">{{ typeLabel }}</div>
    <h3 class="font-medium leading-none xl:text-md">{{ label }}</h3>
  </div>
</template>

<script lang="ts" setup>
import type { SearchSuggestionType } from '~/sites/pharmago/server/api/suggestions'

const props = defineProps<{
  label: string
  type: SearchSuggestionType
}>()

const { $texts } = useNuxtApp()
const typeLabel = computed(() => {
  if (props.type === 'pharmacy_group' || props.type === 'pharmacy_chain') {
    return $texts('navigator.suggestionLabelBrand', 'Marke')
  } else if (props.type === 'phs_portfolio_type_category') {
    return $texts('navigator.serviceCategory', 'Dienstleistung')
  }
})
</script>
